import React, { Fragment } from "react";
import $ from "jquery";
import classnames from 'classnames';
import { domain } from "./../../../utils";
import arrowLeft from "../../../images/icons/arrow-left.svg";
import arrowRight from "../../../images/icons/arrow-right.svg";

export class BookSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = { slideCount: 0, slider: [] }
    this.handleClick = this.handleClick.bind(this)
    this.resolveClassNames = this.resolveClassNames.bind(this)
  }

  componentDidMount() {
    let sliderItem = [];
    let sliderArray = [];
    this.props.slider.forEach(slide => {
      sliderItem.push(slide);
    });
    let sliderItemCollection = Array.apply(null, Array(3)).map(_ => sliderItem);
    sliderItemCollection.forEach(slider => {
      slider.forEach(slide => {
        sliderArray.push(slide);
      });
    });
    
    this.setState({slider: sliderArray});
  }

  handleClick(type) {
    if (type === "next") {
      $(`#slide-${this.state.slideCount}`)
        .next()
        .addClass("flipped")
      $(`#slide-${this.state.slideCount + 2}`).addClass("active")
      $(".active")
        .next()
        .addClass("active-mute")
      this.setState({
        slideCount:
          this.state.slideCount < this.state.slider.length - 1
            ? this.state.slideCount + 1
            : 0,
      })
    }
    if (type === "prev") {
      $(`#slide-${this.state.slideCount - 1}`)
        .next()
        .removeClass("flipped")
      this.setState({
        slideCount:
          this.state.slideCount - 1 >= 0
            ? this.state.slideCount - 1
            : this.state.slider.length - 1,
      })
    }
  }

  resolveClassNames(slideNo) {
    const { slideCount, slider } = this.state;
    
    if (slideCount === slideNo) {
      return "page active";
    } else if (slideCount + 1 === slideNo && slideCount + 1 <= slider.length ) {
      return "page active-mute";
    } else if (slideCount + 1 === slider.length && slideNo === 0) {
      return "page active-mute";
    } else if (slideCount - 1 === slideNo && slideCount <= slider.length ) {
      return "page flipped active";
    } else if ((slideCount === 1 && slideNo === 0) || (slideCount === 0 && slideNo === slider.length - 1)) {
      return "page flipped active";
    } else if (slideCount - 2 === slideNo && slideCount <= slider.length ) {
      return "page flipped active-mute";
    } else if ((slideCount === 2 && slideNo === 0) || (slideCount === 1 && slideNo === slider.length - 1) || (slideCount === 0 && slideNo === slider.length - 2)) {
      return "page flipped active-mute";
    } else {
      return "page";
    }
  }

  render() {
    const { slider } = this.state;
    return (
      <Fragment>
        <section className="partial-book-slider d-md-block mb-5 mt-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <div
                  className="partial-book light-shadow"
                >
                  <div className="book-bg-left bg-primary">
                    <div className="content-wrap">
                      <div className="content h-100">
                        {slider[0] &&
                          slider[0].slideContent &&
                          slider[0].slideContent.map((obj, i) => {
                            const k = `slide_content_${i}`
                            return (
                              <div key={k} className="container-fluid">
                                <div className="row">
                                  {obj.Icon && obj.Icon.url && (
                                    <div className="d-none d-md-block col-2 pr-0">
                                      <div>
                                        <img 
                                          className="mw-100 text-white" 
                                          src={`${domain}${obj.Icon.url}`} 
                                          alt={`image_${i}`}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className={classnames('col-12 ', {'col-md-10': obj.Icon && obj.Icon.url})}>
                                    <div className="text-white font-weight-bold">
                                      {obj.text}
                                    </div>
                                    <p>{obj.description}</p>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                  {slider.map((slide, index) => {
                    const key = `slide-${index}`
                    return (
                      <div
                        key={key}
                        id={key}
                        className={this.resolveClassNames(index)}
                      >
                        <div className="content-wrap">
                          <div className="content h-100">
                            {slide.slideContent &&
                              slide.slideContent.map((obj, i) => {
                                const k = `slide_${index}_content_${i}`
                                return (
                                  <div key={k} className="container-fluid">
                                    <div className="row">
                                      {obj.Icon && obj.Icon.url && (
                                        <div className="d-none d-md-block col-2 pr-0">
                                          <div>
                                            <img 
                                              className="mw-100 text-white" 
                                              src={`${domain}${obj.Icon.url}`} 
                                              alt={`image_${i}`}
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <div className={classnames('col-12 ', {'col-md-10': obj.Icon && obj.Icon.url})}>
                                        <div className="text-white font-weight-bold">
                                          {obj.title}
                                        </div>
                                        <p>{obj.description}</p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                        {slide.img && (
                          <div
                            className="img-wrap"
                            style={{
                              backgroundImage: `url("${domain}${slide.img.url}")`,
                            }}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="col-12 text-center">
                <div className="text-center my-4 slider-controls">
                  <button id="prev" onClick={e => this.handleClick("prev")}>
                    <img src={arrowLeft} alt="previous"/>
                  </button>
                  <button id="next" onClick={e => this.handleClick("next")}>
                    <img src={arrowRight} alt="next" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}
