import React from "react"
import { domain } from "../../../utils"
import classnames from 'classnames';

export const FeaturesPartial = ({ features, title }) => {
  if (!features) { return <></>; }

  return (
    <section>
      <div className="container-fluid">
        <div className="row mt-3 mb-5">
          <div className="col-md-10 offset-md-1 col-12">
            {title && (
              <div className="title-small border-blue mb-2 mb-md-5">
                <h2 className="text-uppercase">{title}</h2>
              </div>
            )}
            {features.map(
              ({heading, bigHeading, description, image }, index) => {
                return (
                  <div key={index} className={classnames('row', index === 0 ? 'pb-5' : 'py-5')}>
                    <div
                      className={`text-center col-12 col-md-4 col-lg-4${
                        index % 2 === 0
                          ? " offset-lg-1"
                          : " order-md-12 float-right offset-lg-1"
                      }`}
                    >
                      <img
                        className="mw-100"
                        alt={heading}
                        src={`${domain}${image.url}`}
                      />
                    </div>
                    <div
                      className={`col-12 col-md-8 col-lg-5 ${
                        index % 2 === 0
                          ? "float-right my-auto pl-md-4 offset-lg-1"
                          : "offset-lg-1 order-md-1 my-auto pr-md-4"
                      }`}
                    >
                      <div className="small">
                        <small>{heading}</small>
                      </div>
                      <h4 className="h1">{bigHeading}</h4>
                      <p className="md-2 text-left">{description}</p>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
