import React, { Fragment, useEffect, useContext } from "react";
import { graphql } from "gatsby";
import SEO from "../components/layout/seo";
import { Faq } from "../components/partials/faq/faq";
import { Subscription } from "../components/partials/subscription/subscription";
import { BannerReverse } from "../components/partials/banner/bannerReverse";
import { FeaturesPartial } from "../components/partials/features/featuresPartial";
import { BookSlider } from "../components/partials/slider/bookSlider";
import googlePlay from "../images/google-play-store.svg";
import { Footer } from "../components/layout/footer";
import { NavbarLanding } from "../components/layout/navbarLanding";
import { PricingCard } from "../components/partials/pricing/pricingCard";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";

const LandingPage = ({ data }) => {
  const { landingpage, homepages, pricings } = data.strapi;
  const {
    language,
    banner,
    features,
    featuresTitle,
    book_slider,
    subscriptionHeading,
    subscriptionDescription,
    faqHeading,
    faqItems,
    meta,
  } = landingpage;
  const { header, footer } = homepages[0];

  const dispatch = useContext(GlobalDispatchContext);
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language });
  });

  const AOS = require("aos");
  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  });
  useEffect(() => {
    AOS.refresh();
  });
  
  const Slider =  book_slider ?  book_slider.bookSlider : undefined;
  const {pricingModels} = pricings[0];
  return (
    <Fragment>
      <NavbarLanding header={header} />
      <SEO title={meta.title} description={meta.description}/>
      <BannerReverse banner={banner} />
      {Slider !== undefined && (
        <BookSlider slider={Slider} />
      )}
      <FeaturesPartial features={features} title={featuresTitle} />
      <div className="container-fluid">
        {pricingModels && pricingModels.length > 0 && (
          <div className="row position-relative mb-3">
            <div className="bg-overlay bg-primary" />
            <PricingCard pricingModels={pricingModels} />
          </div>
        )}
        <div className="row mb-5">
          <div className="col-12 px-md-5 pb-md-5 text-center">
            <a
              className="p-0 my-btn-store m-2"
              href="https://play.google.com/store/apps/details?id=com.coopzapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="Google Play" src={googlePlay} width="auto" />
            </a>
          </div>
        </div>
      </div>
      <Faq faqHeading={faqHeading} faqItems={faqItems} />
      <Subscription subscriptionHeading={subscriptionHeading} subscriptionDescription={subscriptionDescription}/>
      <Footer footer={footer}/>
    </Fragment>
  )
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPage($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      landingpage(id: $id) {
        language
        banner {
          description
          title
        }
        faqHeading
        faqItems {
          question
          answer
        }
        
        book_slider {
          bookSlider {
            img {
              url
            }
            slideContent {
              Icon {
                url
              }
              title
              description
            }
          }
        }

        subscriptionDescription
        subscriptionHeading

        featuresTitle
        features {
          bigHeading
          heading
          description
          image {
            url
          }
        }
        faqHeading
        faqItems {
          question
          answer
        }
        meta {
          title
          description
        }
      }
      homepages(where: $language) {
        header {
          links {
            label
            slug
          }
          logo {
            url
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
      }

      pricings(where: $language) {
        pricingModels {
          discount
          features
          monthly
          monthlyPrice
          pricingType
          subscriptionPrice
        }
      }
    }
  }
`
